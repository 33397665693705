/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const Blink: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M7.81362 1.77734V5.65208' stroke='currentColor' strokeWidth='2.59375' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M7.81362 10.8184V14.6931' stroke='currentColor' strokeWidth='2.59375' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M1.35571 8.2357H5.23045' stroke='currentColor' strokeWidth='2.59375' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M10.3967 8.2357H14.2715' stroke='currentColor' strokeWidth='2.59375' strokeLinecap='round' strokeLinejoin='round' />
        </svg>;
};

export default Blink;